
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "users-form",
  emit: ["update-success"],
  components: {},
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref({
      username: "", // Solo el campo de usuario
      source: "",
    });

    const rules = ref({
      username: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 50,
          message: "Este campo admite máximo 50 caracteres",
          trigger: "change",
        },
      ],
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Usuarios", ["Usuarios", "Nuevo"]);
      setCurrentPageActions(false, false, []);
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          formData.value.source = "A";
          store
            .dispatch(Actions.RECOVER, formData.value)
            .then(() => {
              loading.value = false;
              if (store.state.AuthModule.success) {
                //emit("update-success");
                ToastService.show(
                  "Se ha enviado un correo electrónico con la información suministrada para que recuperar la contraseña",
                  "success"
                );
              }
            })
            .catch((response) => {
              displayErrors(store, "AuthModule");
              loading.value = false;
            });
        } else {
          ToastService.show(
            "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
            "error"
          );
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
    };
  },
});
